@import url("https://fonts.googleapis.com/css2?family=Karla:wght@700&family=Roboto:wght@100&family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@800&family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

/* App.css */
.App {
  font-family: Arial, sans-serif;
}

header {
  background-color: #333;
  color: #fff;
  padding: 10px 0;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

li {
  margin: 0 20px;
}

a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}
