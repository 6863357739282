.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 3px 0;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: flex;
  }

  ul {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: -100%;
    background-color: #333;
    width: 100%;
    transition: left 0.3s ease;
  }

  ul.active {
    left: 0;
  }

  li {
    margin: 15px 0;
  }
}
